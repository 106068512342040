import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/svgIcon/index.js"


import loadMore from './directive/loadMore' // 下拉加载更多
Vue.use(loadMore);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import htmlToPdf from '@/components/htmlToPdf'

// 图表
import echarts from 'echarts';

Vue.prototype.$echarts = echarts;

Vue.use(htmlToPdf)
Vue.use(ElementUI);

Vue.config.productionTip = false

// TODO 这个到底有没有用，不知道
// Vue.prototype.$urls = {
//   upload: 'api/public/bupload'
// };

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default{
    install (Vue, options) {
        // console.log(options)

        Vue.prototype.getPdf = function () {
            // window.pageYoffset = 0;
            // document.documentElement.scrollTop = 0;
            // document.body.scrollTop = 0;
            var title = this.htmlTitle
            setTimeout(() => {

                html2Canvas(document.querySelector('#tableHead'), {
                    allowTaint: true
                }).then(function (canvas) {
                    html2Canvas(document.querySelector('#tableBody'), {
                        allowTaint: true
                    }).then(function (canvas2) {
                        let PDF = new JsPDF('', 'pt', 'a4')

                        let contentWidth = canvas.width
                        let contentHeight = canvas.height
                        let pageHeight = contentWidth / 592.28 * 841.89
                        let leftHeight = contentHeight
                        let position = 0
                        let imgWidth = 595.28
                        let imgHeight = 592.28 / contentWidth * contentHeight
                        let pageData = canvas.toDataURL('image/jpeg', 1.0)
                        if (leftHeight < pageHeight) {
                            PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                        } else {
                            while (leftHeight > 0) {
                                PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                                leftHeight -= pageHeight
                                position -= 841.89
                                if (leftHeight > 0) {
                                    PDF.addPage()
                                }
                            }
                        }
                        // position -= 841.89
                        PDF.addPage()
                        position = 0
                        
                        let contentWidth2 = canvas2.width
                        let contentHeight2 = canvas2.height
                        let pageHeight2 = contentWidth2 / 592.28 * 841.89
                        let leftHeight2 = contentHeight2
                        let imgWidth2 = 595.28
                        let imgHeight2 = 592.28 / contentWidth2 * contentHeight2
                        let pageData2 = canvas2.toDataURL('image/jpeg', 1.0)
                        if (leftHeight2 < pageHeight2) {
                            PDF.addImage(pageData2, 'JPEG', 0, 0, imgWidth2, imgHeight2)
                        } else {
                            while (leftHeight2 > 0) {
                                PDF.addImage(pageData2, 'JPEG', 0, position, imgWidth2, imgHeight2)
                                leftHeight2 -= pageHeight2
                                position -= 841.89
                                if (leftHeight2 > 0) {
                                    PDF.addPage()
                                }
                            }
                        }

                        PDF.save(title + '.pdf')

                    })
                })

            }, 500);

    }

    }
}







// // // 导出页面为PDF格式
// import html2Canvas from 'html2canvas'
// import JsPDF from 'jspdf'
// export default{
//     install (Vue, options) {
//         console.log(options)
        
//         Vue.prototype.getPdf = function () {
//             // window.pageYoffset = 0;
//             // document.documentElement.scrollTop = 0;
//             // document.body.scrollTop = 0;
//             var title = this.htmlTitle
//             setTimeout(() => {
//                 html2Canvas(document.querySelector('#pdfDom'), {
//                     allowTaint: true
//                 }).then(function (canvas) {
//                     let contentWidth = canvas.width
//                     let contentHeight = canvas.height
//                     let pageHeight = contentWidth / 592.28 * 841.89
//                     let leftHeight = contentHeight
//                     let position = 0
//                     let imgWidth = 595.28
//                     let imgHeight = 592.28 / contentWidth * contentHeight
//                     let pageData = canvas.toDataURL('image/jpeg', 1.0)
//                     let PDF = new JsPDF('', 'pt', 'a4')
//                     if (leftHeight < pageHeight) {
//                         PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//                     } else {
//                         while (leftHeight > 0) {
//                             PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//                             leftHeight -= pageHeight
//                             position -= 841.89
//                             if (leftHeight > 0) {
//                                 PDF.addPage()
//                             }
//                         }
//                     }
//                     PDF.save(title + '.pdf')
//                     }
//                 )
//             }, 500);
            
//         }
//     }
// }




